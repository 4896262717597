
export const initialState = {
    tools: [],
    providers: [],
    categories:[],
    tool: null,
    provider: null,
    category: null,
    connections: [],
    connectionsLoading: false
}

const toolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CONNECTION_UPDATED':
            const connectionExisted = state.connections.find((connection) => connection.pieceName === action.payload.pieceName)
            if (connectionExisted) {
                return {
                    ...state,
                    connections: state.connections.map((connection) => (connection._id === connectionExisted._id ? action.payload : connection))
                }
            } else {
            return {
                ...state,
                connections: [...state.connections, action.payload]
            }
        }
        case 'ADD_CONNECTION':
            return {
                ...state,
                connections: [...state.connections, action.payload]
            }
        case 'SET_CONNECTIONS':
            return {
                ...state,
                connections: action.payload
            }
        case 'DELETE_CONNECTION':
            return {
                ...state,
                connections: state.connections.filter((connection) => connection._id !== action.payload)
            }
        case 'SET_CONNECTIONS_LOADING':
            return {
                ...state,
                connectionsLoading: action.payload
            }
        case 'SET_TOOL':
            return {
                ...state,
                tool: action.payload
            }
        case 'SET_PROVIDERS':
            return {
                ...state,
                providers: action.payload
            }
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: action.payload
            }
        case 'ADD_TOOL':
            return {
                ...state,
                tools: [...state.tools, action.payload]
            }
        case 'SET_TOOLS':
            return {
                ...state,
                tools: action.payload ?? []
            }
        case 'UPDATE_TOOL':
            return {
                ...state,
                tools: state.tools.map((tool) => (tool._id === action.payload._id ? action.payload : tool))
            }
        case 'DELETE_TOOL':
            return {
                ...state,
                tools: state.tools.filter((tool) => tool._id !== action.payload)
            }
        case 'SET_PROVIDER':
            return {
                ...state,
                provider: action.payload
            }
        case 'UPDATE_PROVIDER':
            return {
                ...state,
                providers: state.providers.map((provider) => (provider._id === action.payload._id ? action.payload : provider))
            }
        case 'DELETE_PROVIDER':
            return {
                ...state,
                providers: state.providers.filter((provider) => provider._id !== action.payload)
            }
        case 'ADD_PROVIDER':
            return {
                ...state,
                providers: [...state.providers, action.payload]
            }
            case 'SET_CATEGORY':
            return {
                ...state,
                category: action.payload
            }
        case 'UPDATE_CATEGORY':
            return {
                ...state,
                categories: state.categories.map((category) => (category._id === action.payload._id ? action.payload : category))
            }
        case 'DELETE_CATEGORY':
            return {
                ...state,
                categories: state.categories.filter((category) => category._id !== action.payload)
            }
        case 'ADD_CATEGORY':
            return {
                ...state,
                categories: [...state.categories, action.payload]
            }
        default:
            return state
    }
}

export default toolsReducer
