/* eslint-disable import/no-anonymous-default-export */
import client from './client'

const getTools = () => client.get('/tools')

const getTool = (id) => client.get(`/tools/${id}`)

const createNewTool = (body) => client.post(`/tools`, body)

const updateTool = (id, body) => client.put(`/tools/${id}`, body)

const deleteTool = (id) => client.delete(`/tools/${id}`)

const getPrebuiltTools = () => client.get(`/provider`)

export default {
    getTools,
    getTool,
    createNewTool,
    updateTool,
    deleteTool,
    getPrebuiltTools,
    generateHandler: (tool) => client.post(`/tool-import/regenerate-handler`, tool),
    updateToolStatus: (id, body) => client.put(`/tools/${id}/status`, body),
}
