/* eslint-disable jsx-a11y/no-autofocus */
import Page from '../../components/Page'
import { useDispatch } from 'react-redux'
import { resendOtp } from './store/actions'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { SpinButton } from 'tailwindui/pages/components/Spin'
import Button from 'tailwindui/pages/components/Button'
import useAuth from '../../hooks/useAuth'

export default function VerifyOtp({ email }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { verifyOtp } = useAuth()
    const { enqueueSnackbar } = useSnackbar()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState(false)
    const [countdown, setCountdown] = useState(90) // Start with 90 seconds countdown
    
    // State for each digit of the code
    const [code, setCode] = useState({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: ''
    })

    const resend = () => {
        dispatch(resendOtp(email))
        setCountdown(90) // Reset countdown to 90 seconds
    }

    // Countdown timer effect
    useEffect(() => {
        let timer
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1)
            }, 1000)
        }
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [countdown])

    // Format seconds to MM:SS
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60)
        const secs = seconds % 60
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
    }

    useEffect(() => {
        const handlePaste = (event) => {
            event.preventDefault()
            const pastedData = event.clipboardData.getData('text')
            
            if (pastedData.length <= 6) {
                const digits = pastedData.split('')
                const newCode = { ...code }
                
                digits.forEach((digit, index) => {
                    if (index < 6) {
                        const fieldName = `code${index + 1}`
                        newCode[fieldName] = digit
                    }
                })
                
                setCode(newCode)
                
                // Focus on the next empty field or the last field
                const lastFilledIndex = Math.min(digits.length, 6)
                if (lastFilledIndex < 6) {
                    const nextField = document.querySelector(`input[name=code${lastFilledIndex + 1}]`)
                    if (nextField) nextField.focus()
                } else {
                    const lastField = document.querySelector(`input[name=code6]`)
                    if (lastField) lastField.focus()
                }
            }
        }

        const firstInput = document.querySelector('input[name=code1]')
        firstInput?.addEventListener('paste', handlePaste)

        return () => {
            firstInput?.removeEventListener('paste', handlePaste)
        }
    }, [code])

    const handleChange = (e) => {
        const { name, value } = e.target
        
        // Only allow digits
        if (value && !/^\d*$/.test(value)) return
        
        // Update the code state
        setCode({
            ...code,
            [name]: value.slice(0, 1) // Only take the first character
        })
        
        // Move to next field if value is entered
        if (value.length === 1) {
            const fieldIndex = parseInt(name.replace('code', ''))
            if (fieldIndex < 6) {
                const nextField = document.querySelector(`input[name=code${fieldIndex + 1}]`)
                if (nextField) nextField.focus()
            }
        }
    }
    
    const handleKeyDown = (e) => {
        const { name, value } = e.target
        
        // Handle backspace to go to previous field
        if (e.key === 'Backspace' && !value) {
            const fieldIndex = parseInt(name.replace('code', ''))
            if (fieldIndex > 1) {
                const prevField = document.querySelector(`input[name=code${fieldIndex - 1}]`)
                if (prevField) {
                    prevField.focus()
                    // Optional: clear the previous field
                    // setCode({
                    //     ...code,
                    //     [`code${fieldIndex - 1}`]: ''
                    // })
                }
            }
        }
    }

    const validateCode = () => {
        // Check if all fields are filled
        for (let i = 1; i <= 6; i++) {
            if (!code[`code${i}`]) {
                setError(true)
                return false
            }
        }
        setError(false)
        return true
    }

    const afterLogin = (isAdmin) => {
        navigate('/dashboard')
    }

    const cb = (data) => {
        if (data.msg) {
            enqueueSnackbar(data.msg, { variant: data.status ? 'success' : 'error' })
        }
    }

    const onSubmit = async () => {
        if (!validateCode()) return
        
        setIsSubmitting(true)
        try {
            const otp = Object.values(code).join('')
            verifyOtp(email, otp, cb, afterLogin)
        } catch (error) {
            console.error(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Page title='Verify Code'>
            <div className='flex flex-col items-center justify-center min-h-screen py-12 px-4 sm:px-6 lg:px-8'>
                <div className='max-w-md w-full space-y-8'>
                    <img className='mx-auto h-12 w-auto' src='/logo/logo.svg' alt='Workflow' />
                </div>
                <div className='max-w-md w-full space-y-8 text-center'>
                    <h3 className='text-2xl font-medium leading-6 text-gray-900 mt-8'>
                        Please check your email!
                    </h3>

                    <p className='mt-2 text-center text-sm text-gray-600'>
                        We have emailed a 6-digit confirmation code, please enter the code in below box to verify your email.
                    </p>

                    <div className="mt-5 mb-3">
                        <div className="space-y-4">
                            <div className="flex justify-center space-x-2">
                                {[1, 2, 3, 4, 5, 6].map((digit) => (
                                    <input
                                        key={digit}
                                        name={`code${digit}`}
                                        type="text"
                                        autoFocus={digit === 1}
                                        value={code[`code${digit}`]}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        maxLength={1}
                                        className="w-12 h-12 sm:w-14 sm:h-14 text-center text-lg font-semibold border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                        placeholder="-"
                                    />
                                ))}
                            </div>
                            
                            {error && (
                                <p className="text-red-500 text-xs text-center">
                                    Please enter all digits of the code
                                </p>
                            )}
                            
                            {isSubmitting ? (
                                <SpinButton title='Verifying...' fullWidth />
                            ) : (
                                <Button variant='primary' fullWidth onClick={onSubmit}>
                                    Verify
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className='text-sm'>
                        {countdown > 0 ? (
                            <p className="text-gray-600">
                                You can resend the code in {formatTime(countdown)}
                            </p>
                        ) : (
                            <>
                                Don't have a code? &nbsp;
                                <button 
                                    className='font-medium text-indigo-600 hover:text-indigo-500'
                                    onClick={resend}
                                >
                                    Resend code
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Page>
    )
}
