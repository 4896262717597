/* eslint-disable import/no-anonymous-default-export */
import client from './client'
import { dispatch, set, state } from 'store'
import { switchSheet } from '../tailwindui/pages/datagrid/utils'
const toast = (message, type) => {
    dispatch({ type: 'TOAST', payload: { message, type } })
}
// Get the active ID (sheet ID if available, otherwise table ID)
// Sheet operations
const createSheet = (tableId, payload) => client.post(`/sheets/${tableId}/create`, payload)
export const getSheet = (tableId) => client.get(`/sheets/${tableId}`)
const getSheets = (tableId, params) => client.get(`/sheets/${tableId}/list`, {
    params: {
        ...params,
        limit: 20 // Get first 20 rows
    }
})
const getSheetRows = (sheetId, params = { page: 1, limit: 20 }) => client.get(`/sheets/${sheetId}/rows`, {
    params: {
        page: params.page || 1,
        limit: params.limit || 20
    }
})
const deleteSheet = (sheetId) => client.delete(`/sheets/${sheetId}`)
const getTableBatchStats = (tableId, cleanup) => client.get(`/batches/stats/${tableId}?cleanup=${cleanup}`)
const resetTableBatch = (tableId) => client.delete(`/batches/stats/${tableId}`)
export const fetchSheet = async (sheet, newActiveSheetId) => {
    // handleDuplicateDialog(false)
    if (!sheet?._id) return
    try {
        dispatch({ type: 'ADD_SHEET', payload: sheet })

        let totalPages = Math.ceil(sheet.totalRows / 100)
        Array.from({ length: totalPages }).forEach(async (_, i) => {
            getSheetRows(sheet._id, { page: i + 1, limit: 100 }).then((res) => {
                if (res.status && res.data) {
                    let rows = res.data.rows || res.data
                    if (newActiveSheetId === sheet._id) {
                        const rws = rows.map((row) => ({ ...row, id: row._id.toString() }))
                        dispatch({ type: 'ADD_ROWS_DG', payload: rws })
                        dispatch({ type: 'ADD_TABLE_ROWS', payload: rws.map((row) => row._id) })
                    } else {
                        dispatch({
                            type: 'APPEND_SHEET_ROWS', payload: {
                                sheetId: sheet._id,
                                rows
                            }
                        })
                    }
                }
            }).catch((error) => {
                console.error('Failed to fetch rows:', error)
            })
        })

    } catch (error) {
        console.error('Failed to fetch sheets:', error)
        toast('Failed to fetch sheets', 'error')
    }
}
export const fetchSheetRows = async (sheet) => {
    if (!sheet?._id) return
    try {
        let totalPages = Math.ceil(sheet.totalRows / 100)
        Array.from({ length: totalPages }).forEach(async (_, i) => {
            getSheetRows(sheet._id, { page: i + 1, limit: 100 }).then((res) => {
                if (res.status && res.data) {
                    let rows = res.data.rows || res.data
                        dispatch({
                            type: 'APPEND_SHEET_ROWS', payload: {
                                sheetId: sheet._id,
                                rows
                            }
                        })
                }
            }).catch((error) => {
                console.error('Failed to fetch rows:', error)
            })
        })

    } catch (error) {
        console.error('Failed to fetch sheets:', error)
        toast('Failed to fetch sheets', 'error')
    }
}
export const fetchSheets = async (tableId) => {
    if (!tableId) return
    try {
        dispatch({ type: 'SET_SHEETS_LOADING', payload: true })
        dispatch({ type: 'SET_SHEETS_LOADED', payload: false })
        const result = await getSheets(tableId)
        if (result.status && result.data) {
            const sheetsData = Array.isArray(result.data) ? result.data : []
            dispatch({ type: 'SET_SHEETS', payload: sheetsData })
            dispatch({ type: 'SET_SHEETS_LOADING', payload: false })
            dispatch({ type: 'SET_SHEETS_LOADED', payload: true })
            const initialSheet = sheetsData[0]
            if (initialSheet) {
                switchSheet(initialSheet)
            }

            // Load all data for each sheet
            for (const sheet of sheetsData) {
                let totalPages = Math.ceil(sheet.totalRows / 100)
                if (sheet.totalRows > 20) {
                    Array.from({ length: totalPages }).forEach(async (_, i) => {
                        getSheetRows(sheet._id, { page: i + 1, limit: 100 }).then((res) => {
                            if (res.status && res.data) {
                                let rows = res.data.rows || res.data
                                if (i === 0) {
                                    rows = rows.slice(20)
                                }
                                const newActiveSheetId = tableId
                                if (newActiveSheetId === sheet._id) {
                                    const rws = rows.map((row) => ({ ...row, id: row._id.toString() }))
                                    dispatch({ type: 'ADD_ROWS_DG', payload: rws })
                                    dispatch({ type: 'ADD_TABLE_ROWS', payload: rws.map((row) => row._id) })
                                } else {
                                    dispatch({
                                        type: 'APPEND_SHEET_ROWS', payload: {
                                            sheetId: sheet._id,
                                            rows
                                        }
                                    })
                                }
                            }
                        }).catch((error) => {
                            console.error('Failed to fetch rows:', error)
                        })
                    })
                }
            }

        }
    } catch (error) {
        console.error('Failed to fetch sheets:', error)
        toast('Failed to fetch sheets', 'error')
    }
}
export const fetchNewSheets = async (duplicatedTableData) => {
    const sh = {
        ...duplicatedTableData.table,
        cells: duplicatedTableData.cells,
        totalRows: duplicatedTableData.table.rows.length,
        rows: []
    }
    fetchSheet(sh)

}
export const checkSheetData =(payload)=>{
    if(payload.tableId){
        const sheets = state().sheets.sheets
        const sheet = sheets.find(s => s._id === payload.tableId)
        if (sheet) {
            fetchSheetRows({
                ...sheet,
                totalRows: payload.totalRows
            })
        }
    }
}
export default {
    createSheet,
    getSheets,
    deleteSheet,
    getSheet,
    getSheetRows,
    getTableBatchStats,
    resetTableBatch,
    clearRedisCache: () => client.delete(`/batches/cache`)
}
