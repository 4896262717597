/* eslint-disable react/prop-types */
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { classNames } from './utils'

const TextField = ({ label, placeholder, name, value, onChange, type, noLabel = false, onBlur, disabled = false, className, children, onlyClassName }) => {
    const handleOnBlur = (e) => {
        if (onBlur) {
            onBlur(e)
        }
    }
    return (
        <ClickAwayListener onClickAway={handleOnBlur}>
            <div className='w-full'>
                {noLabel ? null : (
                    <label htmlFor={label} className='block text-sm font-normal leading-6 text-gray-900'>
                        {label}
                    </label>
                )}
                <div className='relative  w-full'>
                    {type === 'textarea' ? (
                        <textarea
                            id={name}
                            name={name}
                            placeholder={placeholder}
                            value={value}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={handleOnBlur}
                            aria-describedby={`${name}-error`}
                            className={classNames(
                                className ? className : 'w-full',
                                'block rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-0.5 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 bg-white'
                            )}
                        />
                    ) : (
                        <input
                            id={name}
                            name={name}
                            type={type ?? 'text'}
                            placeholder={placeholder}
                            // aria-invalid='true'
                            value={value}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={handleOnBlur}
                            aria-describedby={`${name}-error`}
                            className={onlyClassName ? onlyClassName : classNames(
                                className ? className : type === 'number' ? 'w-32 ' : 'w-full',
                                'block w-full p-1.5  text-gray-900 ring-1 ring-inset ring-white placeholder:text-gray-400 focus:ring-inset sm:text-sm sm:leading-6 border rounded-sm focus:ring-1 focus:ring-gray-200 focus:outline-none outline-none'
                            )}
                        />
                    )}
                    {children}
                </div>
            </div>
        </ClickAwayListener>
    )
}
export default TextField
export const InputSmall = ({ label, placeholder, value, onChange, type }) => {
    return (
        <div className='w-full'>
            {label ? (
                <label htmlFor={label} className='block text-sm font-normal leading-6 text-gray-900'>
                    {label}
                </label>
            ) : null}
            <div className='relative  w-full'>
                <input
                    type={type ?? 'text'}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className={classNames(
                        type === 'number' ? 'w-32 ' : 'w-full',
                        'block rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-0.5 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2 bg-white'
                    )}
                />

            </div>
        </div>
    )
}
