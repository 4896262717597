/* eslint-disable import/no-anonymous-default-export */
import client from './client'

const getCategories = () => client.get('/category')

const getCategory = (id) => client.get(`/category/${id}`)

const createNewCategory = (body) => client.post(`/category`, body)

const updateCategory = (id, body) => client.put(`/category/${id}`, body)

const deleteCategory = (id) => client.delete(`/category/${id}`)

export default {
    getCategory,
    getCategories,
    createNewCategory,
    updateCategory,
    deleteCategory,
    getPublicCategories: (isMcpServer) => isMcpServer ? client.get('/public/categories?isMcpServer=true') : client.get('/public/categories')
}
