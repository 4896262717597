'use client'
import { Link, Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
} from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import Sidebar from './Sidebar'
import useAuth from 'hooks/useAuth'
import { useLocation } from 'react-router-dom'
import useSocket from 'hooks/useSocket'
import { dispatch } from 'store'
import useNotifier from 'utils/useNotifier'
const userNavigation = [
    { name: 'Your profile', href: '/dashboard/user/account' },
    { name: 'Sign out', href: '/auth/logout' },
]
const userNavigation2 = [
    { name: 'Login', href: '/auth/login' },
    { name: 'Register', href: '/auth/register' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PublicLayout({ children }) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { user } = useAuth()
    const menu = user ? userNavigation : userNavigation2

    return (
        <>
            <div>
                <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-10 lg:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />

                    <div className="fixed inset-0 flex">
                        <DialogPanel
                            transition
                            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
                        >
                            <TransitionChild>
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                                    <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon aria-hidden="true" className="size-6 text-white" />
                                    </button>
                                </div>
                            </TransitionChild>
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                <div className="flex h-16 shrink-0 items-center">
                                    <img alt='Meerkats.ai' src='/logo/logo.svg'
                                        className="h-8 w-auto"
                                    />
                                </div>
                                <Sidebar />
                            </div>
                        </DialogPanel>
                    </div>
                </Dialog>


                <div className='h-screen w-screen flex flex-col bg-[#f5f5f5]'>
                    <div className='container mx-auto'>
                        <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4   px-4 sm:gap-x-6 sm:px-6 lg:px-8">
                            <div className="flex h-16 shrink-0 items-center lg:w-72">
                                <Link to='/dashboard'>
                                    <img alt='Meerkats.ai' src='/logo/logo.svg'
                                        className="h-8 w-auto"
                                    />
                                </Link>
                            </div>
                            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon aria-hidden="true" className="size-6" />
                            </button>

                            {/* Separator */}
                            <div aria-hidden="true" className="h-6 w-px  lg:hidden" />

                            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                                <div className="relative flex flex-grow focus-within:z-10 justify-center items-center  ">
                                    <div className="w-1/2 mx-auto ">
                                    </div>
                                </div>
                                <div className="flex items-center gap-x-4 lg:gap-x-6">
                                    <Menu as="div" className="relative">
                                        <MenuButton className="-m-1.5 flex items-center p-1.5">
                                            <span className="sr-only">Open user menu</span>
                                            <span className="inline-flex size-8 items-center justify-center rounded-full bg-mtmain">
                                                <span className="text-sm font-medium text-white">

                                                </span>
                                            </span>

                                        </MenuButton>
                                        <MenuItems
                                            transition
                                            className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            {menu.map((item) => (
                                                <MenuItem key={item.name}>
                                                    <Link
                                                        to={item.href}
                                                        className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </MenuItem>
                                            ))}
                                        </MenuItems>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row w-screen h-full bg-white pt-4'>
                        <div className='container mx-auto'>
                            <main className='bg-white '>
                                {children}
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
