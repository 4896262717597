import { lazy } from 'react'

// project imports
import Loadable from 'components/loading/Loadable'
import GuestGuard from '../guards/GuestGuard'
// canvas routing

const Login = Loadable(lazy(() => import('../views/auth/Login')))
const ResetPassword = Loadable(lazy(() => import('../views/auth/ResetPassword')))
const NewPassword = Loadable(lazy(() => import('../views/auth/NewPassword')))
const VerifyCode = Loadable(lazy(() => import('../views/auth/VerifyOtp')))

// ==============================|| CANVAS ROUTING ||============================== //

const AuthRoutes = {
    path: 'auth',
    children: [
        {
            path: 'login',
            element: (
                <GuestGuard>
                    <Login />
                </GuestGuard>
            )
        },
        {
            path: 'register',
            element: (
                <GuestGuard>
                    <Login />
                </GuestGuard>
            )
        },

        {
            path: 'reset-password',
            element: (
                <GuestGuard>
                    <ResetPassword />
                </GuestGuard>
            )
        },
        {
            path: 'new-password',
            element: (
                <GuestGuard>
                    <NewPassword />
                </GuestGuard>
            )
        },
        {
            path: 'verify',
            element: (
                <GuestGuard>
                    <VerifyCode />{' '}
                </GuestGuard>
            )
        }
    ]
}

export default AuthRoutes
