import ScrollToTop from './components/ScrollToTop'
import Routes from 'routes'
import ErrorBoundary from 'ErrorBoundary'
import NavigationScroll from 'layout/NavigationScroll'
import Toast from 'components/Toast'
import useVersionCheck, { isUpdateAvailable } from './utils/useVersionCheck'

const App = () => {
    // Check for app updates every minute, but only refresh on route changes
    const { updateAvailable } = useVersionCheck(60000);
    
    return (
        <ErrorBoundary>
            <NavigationScroll>
                <Toast />
                <ScrollToTop />
                <Routes />
            </NavigationScroll>
        </ErrorBoundary>
    )
}

export default App
