import { dispatch, state } from 'store'
export function handleBroadcastMessage(message) {
    if(!message) return
    const { entity, crudType, data } = message
    if(!entity || !crudType || !data) return
    const st = state()
    //  const { categories, providers, tools, connections } = useSelector((state) => state.tools);
    if(entity === 'Tool'){
        let tools = st.tools.tools
        if(crudType === 'create'){
            tools = [...tools, data]
        }else if(crudType === 'update'){
            tools = tools.map((t) => t._id === data._id ? data : t)
        }else if(crudType === 'delete'){
            tools = tools.filter((t) => t._id !== data)
        }
        console.log('tools', tools)
        dispatch({ type: 'SET_TOOLS', payload: tools })
    } else if(entity === 'Category'){
        let categories = st.categories.categories
        if(crudType === 'create'){
            categories = [...categories, data]
        }else if(crudType === 'update'){
            categories = categories.map((t) => t._id === data._id ? data : t)
        }else if(crudType === 'delete'){
            categories = categories.filter((t) => t._id !== data)
        }
        dispatch({ type: 'SET_CATEGORIES', payload: categories })
    } else if(entity === 'Provider'){
        let providers = st.providers.providers
        if(crudType === 'create'){
            providers = [...providers, data]
        }else if(crudType === 'update'){
            providers = providers.map((t) => t._id === data._id ? data : t)
        }else if(crudType === 'delete'){
            providers = providers.filter((t) => t._id !== data)
        }
        dispatch({ type: 'SET_PROVIDERS', payload: providers })
    }
}

export function updateMsgInLocalStorage(msg) {
    let oldMsgs = JSON.parse(localStorage.getItem('msgs')) || []
    oldMsgs = [...oldMsgs, msg]
    localStorage.setItem('msgs', JSON.stringify(oldMsgs))
}   