import { useEffect, useCallback } from 'react'
import { dispatch, state } from 'store'
import socketIOClient from 'socket.io-client'
import { handleDatagridSocketCells, handleDatagridSocketGridData, handleDatagridSocketGridSingle } from '../tailwindui/pages/datagrid/api'
import { checkSheetData } from '../api/sheets'
import uuidv4 from 'utils/uuidv4'
import { handleBroadcastMessage, updateMsgInLocalStorage } from 'utils/socketUtils'
var msgs = []
let socket

let pingInterval
const validTableId = (tableId) => {
    const sheets = state().sheets.sheets
    const sheet = sheets.find(s => s._id === tableId)
    return !!sheet
}
const isChatOpen = () => {
    return state().chat.openChat
}
const WebSocket = (user) => {
    const token = localStorage.getItem('accessToken')
    const userId = user._id
    const handleLastMessage = useCallback((lastMsg) => {
        if (lastMsg) {
            if (!lastMsg.id) {
                lastMsg.id = uuidv4()
            }
            if (lastMsg.type === 'ROW_EXPANDER_COMPLETED' && validTableId(lastMsg.parentTableId)) {
                dispatch({ type: 'ROW_EXPANDER_COMPLETED', payload: lastMsg })
            } else if (lastMsg.type === 'UPDATE_SINGLE_CELL' && validTableId(lastMsg.parentTableId) && lastMsg.cell) {
                dispatch({ type: 'UPDATE_SINGLE_CELL', payload: lastMsg.cell })
            } else if (lastMsg && lastMsg.type && lastMsg.type === 'tableChat' && validTableId(lastMsg.tableId)) {
                if (lastMsg.finished === true) {
                    dispatch({ type: 'SET_MSG_SENDING', payload: false })
                }
                dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, sender: 'bot' } })
                if (lastMsg.action === 'update' && lastMsg.columns) {
                    // dispatch({ type: 'UPDATE_CELLS_DG', payload: lastMsg.columns })
                    // dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Columns updated', sender: 'bot' } })
                } else if (lastMsg.action === 'delete' && lastMsg.columns) {
                    dispatch({ type: 'DELETE_CELLS_DG', payload: lastMsg.columns })
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, message: 'Columns deleted', sender: 'bot' } })
                } else if (lastMsg.action === 'sort' && lastMsg.columns) {

                    dispatch({ type: 'HANDLE_SORT_BY_LLM', payload: lastMsg })
                    dispatch({ type: 'SET_FILTERS_DG', payload: [] })

                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, message: 'Record Sorted', sender: 'bot' } })
                } else if (lastMsg.action === 'filter' && lastMsg.columns) {
                    dispatch({ type: 'HANDLE_FILTER_BY_LLM', payload: lastMsg })

                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, message: 'Record Filtered', sender: 'bot' } })
                } else if (lastMsg.action === 'group' && lastMsg.columns) {
                    //to do
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, message: 'Record Grouping coming soon', sender: 'bot' } })
                } else if (lastMsg.action === 'graph' && lastMsg.columns) {
                    //to do
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, message: 'Graph coming soon', sender: 'bot' } })
                }
            } else if (lastMsg && lastMsg.type && lastMsg.type === 'SCRAPE_STATUS') {
                if(!isChatOpen()){
                    dispatch({ type: 'START_CHAT_DG', payload: true })
                }
                dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, message: lastMsg.message, sender: 'bot' } })
                if (validTableId(lastMsg.tableId)) {
                    if (lastMsg.openChat) {
                        dispatch({ type: 'START_CHAT_DG', payload: true })
                    }
                    setTimeout(() => {
                        dispatch({ type: 'HANDLE_GRID_RERENDER' })
                    }, 300)
                    if (lastMsg.isCompleted) {
                        dispatch({ type: 'SET_MSG_SENDING', payload: false })
                        dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Scrape completed', sender: 'bot', tableId: lastMsg.tableId } })
                        if (lastMsg.totalRows > 0) {
                            dispatch({ type: 'SET_TOTAL_ROWS', payload: { tableId: lastMsg.tableId, totalRows: lastMsg.totalRows } })
                        }
                    } else {
                        dispatch({ type: 'SET_MSG_SENDING', payload: true })
                    }
                } else {
                }
            }
        }
    }, [])
    const checkSocketConnection = useCallback(() => {
        if (socket && !socket.connected) {
            socket.connect()
        }
    }, [])
    const pingpong = useCallback((id) => {
        if (pingInterval) {
            clearInterval(pingInterval)
        }
        if (id) {
            pingInterval = setInterval(() => {
                if (socket.connected) {
                    socket.emit('ping', { socketId: id, userId: userId })
                }
            }, 6000) // Send ping every minute
        }
    }, [userId])
    const init = useCallback(async () => {
        // Configure socket with auth
        socket = socketIOClient.connect(process.env.REACT_APP_API_URL, {
            auth: {
                userId,
                token
            },
            autoConnect: true,
            reconnection: true,
            reconnectionAttempts: 5, // Number of attempts before giving up
            reconnectionDelay: 3000, // Time to wait between attempts (1 second)
            reconnectionDelayMax: 5000, // Max delay (5 seconds)
            timeout: 20000, // Time before the connection attempt times out (20 seconds)
        })
        socket.on('connect', () => {
            socket.emit('register', userId);
            pingpong(socket.id)
            socket.on('broadcastMessage', (data) => {
                console.log('broadcastMessage', data)
            })
            socket.on('broadcast_message', (data) => {
                handleBroadcastMessage(data)
            })
            socket.on('login_OTP', (data) => {
                dispatch({ type: 'LOGIN_OTP', payload: data })
                return null
            })
            socket.on('private_message', (data) => {
                dispatch({ type: 'SET_SOCKET_DATA', payload: data })
                if(data.type === 'LOGIN_STATUS'){
                    dispatch({ type: 'LOGIN_STATUS', payload: data })
                    return null
                }
               
                if(data.type === 'UPDATE_ROW'){
                    dispatch({ type: 'UPDATE_ROW', payload: data.message })
                    return null
                }
                
                handleDatagridSocketGridSingle(data)
                handleDatagridSocketCells(data)
                handleLastMessage(data)
                if (data.type === 'REFRESH_TABLE') {
                    dispatch({ type: 'REFRESH_TABLE', payload: data })
                }
                if (data.type === 'REFRESH_TABLE_ROWS') {
                    checkSheetData(data)
                }
                if (data.type === 'SET_ENRICHMENT_STATUS_SHEET') {
                    dispatch({ type: 'SET_ENRICHMENT_STATUS_SHEET', payload: data })
                }
            })

            dispatch({ type: 'SET_SOCKET_ID', payload: socket.id })
            dispatch({ type: 'SET_ROOM_ID', payload: 'private_message' })
        })

        socket.on('connect_error', (error) => {
            dispatch({ type: 'SOCKET_ERROR', payload: error.message })
        })

        socket.on('pong', () => {
        })

        socket.on('unauthorized', (error) => {
            dispatch({ type: 'SOCKET_UNAUTHORIZED', payload: error.message })
            socket.disconnect()
        })
        socket.on('reconnect_attempt', (attempt) => {
        });

        socket.on('reconnect', (attempt) => {
            if (pingInterval) {
                clearInterval(pingInterval)
            }
            pingpong(socket.id)
        });
        socket.on('server_restart', () => {
            // Handle necessary reinitialization here
        });
    }, [userId, token, pingpong, handleLastMessage])

    // Handle visibility change to check socket connection
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                checkSocketConnection()
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [checkSocketConnection])

    // Ping-pong mechanism
    useEffect(() => {


        return () => {
            if (pingInterval) {
                clearInterval(pingInterval)
            }
        }
    }, [])

    useEffect(() => {
        if (userId && token) {
            init()
        } else {
        }

        // const cl = setInterval(() => {
        //     if (msgs.length > 0) {
        //         const msgLength = msgs.length
        //         // get all message till length and remove them from array
        //         let getmsgs = msgs.slice(0, msgLength)
        //         msgs = msgs.slice(msgLength)
        //         // Group messages by their unique identifier combination
        //         const messageGroups = {};
        //         getmsgs.forEach(msg => {
        //             const key = `${msg.tableId}-${msg.rowId}-${msg.cellId}`;
        //             if (!messageGroups[key]) {
        //                 messageGroups[key] = [];
        //             }
        //             messageGroups[key].push(msg);
        //         });

        //         // For each group, select message with highest priority (1 is highest)
        //         // If priorities are same or not present, take the latest message
        //         getmsgs = Object.values(messageGroups).map(group => {
        //             return group.reduce((selected, current) => {
        //                 // If no selected message yet, use current
        //                 if (!selected) return current;
                        
        //                 // If current has priority and selected doesn't, use current
        //                 if (current.priority && !selected.priority) return current;
                        
        //                 // If selected has priority and current doesn't, keep selected
        //                 if (!current.priority && selected.priority) return selected;
                        
        //                 // If both have priority, use the one with higher priority (lower number)
        //                 if (current.priority && selected.priority) {
        //                     return current.priority < selected.priority ? current : selected;
        //                 }
                        
        //                 // If neither has priority, use the latest message
        //                 return current;
        //             }, null);
        //         });
        //         handleDatagridSocketGridData(getmsgs)
        //     }
        // }, 1000)

        return () => {
            if (socket) {
                socket.disconnect()
                dispatch({ type: 'CLEANUP_SOCKET' })
            }
            // clearInterval(cl)
        }
    }, [userId, token, init])

    return {
        socket
    }
}
export default WebSocket
