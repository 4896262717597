import { lazy, useEffect } from 'react'

// project imports
import DashboardLayout from '../tailwindui/DashboardLayout'
import PublicLayout from '../tailwindui/PublicLayout'
import Loadable from 'components/loading/Loadable'
import AuthGuard from 'guards/AuthGuard'
import OAuthCallback from 'components/OAuthCallback'
import GuestGuard from 'guards/GuestGuard'
import Main from 'layout/main'
const Login = Loadable(lazy(() => import('../views/auth/Login')))
const NotFound = Loadable(lazy(() => import('views/Page404')))
const CellRunDashboard = Loadable(lazy(() => import('views/CellRunDashboard/CellRunDashboard')))
const BatchDashboard = Loadable(lazy(() => import('views/BatchDashboard')))

const PromptChainsLanding = Loadable(lazy(() => import('views/usertables/PromptChainsLanding')))
const TableWorkspaces = Loadable(lazy(() => import('views/usertables/TableWorkspaces')))
const WorkspaceTables = Loadable(lazy(() => import('views/usertables/WorkspaceTables')))
const SharedWorkbook = Loadable(lazy(() => import('views/workbook/SharedWorkbook')))
const Upgrade = Loadable(lazy(() => import('tailwindui/pages/Upgrade')))
const PaymentSuccess = Loadable(lazy(() => import('tailwindui/pages/payment/Success')))
const PaymentFailed = Loadable(lazy(() => import('tailwindui/pages/payment/Failed')))
const Activity = Loadable(lazy(() => import('views/Activity')))
const Home = Loadable(lazy(() => import('tailwindui/pages/home')))
const TablesList = Loadable(lazy(() => import('tailwindui/pages/home/TableList')))
const TemplateList = Loadable(lazy(() => import('tailwindui/pages/templates/List')))
const TemplateDetail = Loadable(lazy(() => import('tailwindui/pages/templates/Detail')))
const TemplateDetailPublic = Loadable(lazy(() => import('tailwindui/pages/templates/DetailPublic')))
const TemplateEdit = Loadable(lazy(() => import('tailwindui/pages/templates/Edit')))
const Credits = Loadable(lazy(() => import('tailwindui/pages/usage')))
const Playground = Loadable(lazy(() => import('tailwindui/pages/playground')))
const Toollist = Loadable(lazy(() => import('tailwindui/pages/tools/list')))
const ToolView = Loadable(lazy(() => import('tailwindui/pages/tools/view')))
const UsersList = Loadable(lazy(() => import('tailwindui/pages/Users')))
const EditUser = Loadable(lazy(() => import('tailwindui/pages/EditUser')))
const CookieProvider = Loadable(lazy(() => import('tailwindui/pages/cookie-provider')))
const MCPServerList = Loadable(lazy(() => import('tailwindui/pages/mcp/MCPServerList')))
const MCPServerPublicList = Loadable(lazy(() => import('tailwindui/pages/mcp/MCPServerPublicList')))
const MCPServerDetail = Loadable(lazy(() => import('tailwindui/pages/mcp/MCPServerDetail')))
const AddMCPServerPage = Loadable(lazy(() => import('tailwindui/pages/mcp/AddMCPServerPage')))
const UpdateMCPServerPage = Loadable(lazy(() => import('tailwindui/pages/mcp/UpdateMCPServerPage')))

const Logout = () => {
    useEffect(() => {
        localStorage.clear()
        window.location.href = '/'
    }, [])
    return <div>Logout...</div>
}
export const getRoutes = (isAdmin) => {
    if (isAdmin) {
        const UserAccount = Loadable(lazy(() => import('views/dashboard/UserAccount')))
        const User = Loadable(lazy(() => import('views/Users/User')))
        const Connections = Loadable(lazy(() => import('tailwindui/pages/connections/Connections')))
        const Blogs = Loadable(lazy(() => import('views/blogs')))
        const BlogDetail = Loadable(lazy(() => import('views/blogs/blogDetail')))
        const UserReport = Loadable(lazy(() => import('views/Users/UserReport')))
        const Tool = Loadable(lazy(() => import('tailwindui/pages/tools/tool')))
        const Providers = Loadable(lazy(() => import('tailwindui/pages/provider/list')))
        const Provider = Loadable(lazy(() => import('tailwindui/pages/provider/provider')))
        const Categories = Loadable(lazy(() => import('tailwindui/pages/category/list')))
        const Category = Loadable(lazy(() => import('tailwindui/pages/category/category')))
        const TableDetail = Loadable(lazy(() => import('tailwindui/pages/datagrid')))
        const ErrorAnalytics = Loadable(lazy(() => import('tailwindui/pages/error-analytics')))
        const LogViewer = Loadable(lazy(() => import('tailwindui/pages/error-analytics/LogViewer')))
        const ApScrapes = Loadable(lazy(() => import('tailwindui/pages/scrape/list')))
        const ApScrape = Loadable(lazy(() => import('tailwindui/pages/scrape/ApScrape')))
        const ApScrapeList = Loadable(lazy(() => import('tailwindui/pages/scrape/ApScrapeList')))
        const ToolImport = Loadable(lazy(() => import('views/ToolImport/ToolImportView')))
        const Plans = Loadable(lazy(() => import('tailwindui/pages/plans')))

        const MainRoutes = {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <Home />
                },
                {
                    path: '/dashboard/cell-runs',
                    element: <CellRunDashboard />
                },
                {
                    path: '/dashboard/batch/dashboard',
                    element: <BatchDashboard />
                },
                {
                    path: '/dashboard/plans',
                    element: <Plans />
                },
                {
                    path: '/dashboard/playground',
                    element: <Playground />
                },
                {
                    path: '/dashboard/start-with-ai',
                    element: <PromptChainsLanding />
                },
                {
                    path: '/dashboard/templates/new',
                    element: <TemplateEdit />
                },
                {
                    path: '/dashboard/templates/:id/edit',
                    element: <TemplateEdit />
                },
                {
                    path: '/dashboard/templates/:id',
                    element: <TemplateDetail />
                },
                {
                    path: '/dashboard/templates',
                    element: <TemplateList />
                },
                {
                    path: '/dashboard/table/:id/edit',
                    element: <TemplateEdit isTable />
                },
                {
                    path: '/dashboard/table/:id/:rowId',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/table/:id',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/tables/:id',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/tables',
                    element: <TablesList />
                },
                {
                    path: '/dashboard/error-analytics',
                    element: <ErrorAnalytics />
                },
                {
                    path: '/dashboard/error-analytics/logs/:filename',
                    element: <LogViewer />
                },
                {
                    path: '/dashboard/ap-scrape/list/:id',
                    element: <ApScrapeList />
                },
                {
                    path: '/dashboard/ap-scrape/:id',
                    element: <ApScrape />
                },
                {
                    path: '/dashboard/ap-scrape',
                    element: <ApScrapes />
                },
                {
                    path: '/dashboard/upgrade',
                    element: <Upgrade />
                },
                {
                    path: '/dashboard/payment/success',
                    element: <PaymentSuccess />
                },
                {
                    path: '/dashboard/payment/failed',
                    element: <PaymentFailed />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/activity/:type',
                    element: <Activity />
                },
                {
                    path: '/dashboard/users/:id/documents',
                    element: <UserReport entity='documents' />
                },
                {
                    path: '/dashboard/users/:id/runs',
                    element: <UserReport entity='runs' />
                },
                {
                    path: '/dashboard/users/:id/prompt-chains',
                    element: <UserReport entity='promt-chains' />
                },
                {
                    path: '/dashboard/tools/import',
                    element: <ToolImport />
                },
                {
                    path: '/dashboard/tools/view/:id',
                    element: <ToolView />
                },
                {
                    path: '/dashboard/tools/:id',
                    element: <Tool isEdit />
                },
                {
                    path: '/dashboard/tools',
                    element: <Toollist />
                },
                {
                    path: '/dashboard/provider/:id',
                    element: <Provider isEdit />
                },
                {
                    path: '/dashboard/provider',
                    element: <Providers />
                },
                {
                    path: '/dashboard/cookie-provider',
                    element: <CookieProvider />
                },
                {
                    path: '/dashboard/category/:id',
                    element: <Category isEdit />
                },
                {
                    path: '/dashboard/category',
                    element: <Categories />
                },
                {
                    path: '/dashboard/connections',
                    element: <Connections />
                },
                {
                    path: '/dashboard/system-connections',
                    element: <Connections isSystem />
                },
                {
                    path: '/dashboard/usage/:id',
                    element: <Credits />
                },
                {
                    path: '/dashboard/usage',
                    element: <Credits />
                },
                {
                    path: '/dashboard/users/:id/edit',
                    element: <EditUser />
                },
                {
                    path: '/dashboard/users/:id',
                    element: <User />
                },
                {
                    path: '/dashboard/users',
                    element: <UsersList />
                },
                {
                    path: '/dashboard/user/account',
                    element: <UserAccount />
                },
                {
                    path: '/dashboard/blogs/new',
                    element: <BlogDetail isNew />
                },
                {
                    path: '/dashboard/blogs/:id',
                    element: <BlogDetail />
                },
                {
                    path: '/dashboard/blogs',
                    element: <Blogs />
                },
                {
                    path: '/dashboard/workspaces',
                    element: <TableWorkspaces />
                },
                {
                    path: '/dashboard/workspaces/:id/tables',
                    element: <WorkspaceTables />
                },
                {
                    path: '/dashboard/mcp/servers',
                    element: <MCPServerList />
                },
                {
                    path: '/dashboard/mcp/servers/new',
                    element: <AddMCPServerPage />
                },
                {
                    path: '/dashboard/mcp/servers/:id/edit',
                    element: <UpdateMCPServerPage />
                },
                {
                    path: '/dashboard/mcp/servers/:id',
                    element: <MCPServerDetail />
                }
            ]
        }
        return MainRoutes
    } else {
        const UserAccount = Loadable(lazy(() => import('views/dashboard/UserAccount')))
        const Connections = Loadable(lazy(() => import('tailwindui/pages/connections/Connections')))
        const TableDetail = Loadable(lazy(() => import('tailwindui/pages/datagrid')))
        const ErrorAnalytics = Loadable(lazy(() => import('tailwindui/pages/error-analytics')))
        const LogViewer = Loadable(lazy(() => import('tailwindui/pages/error-analytics/LogViewer')))
        const MainRoutes = {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '/dashboard',
                    element: <Home />
                },
                {
                    path: '/dashboard/cell-runs',
                    element: <CellRunDashboard />
                },
                {
                    path: '/dashboard/batch/dashboard',
                    element: <BatchDashboard />
                },
                {
                    path: '/dashboard/playground',
                    element: <Playground />
                },
                {
                    path: '/dashboard/templates/:id',
                    element: <TemplateDetail />
                },
                {
                    path: '/dashboard/templates',
                    element: <TemplateList />
                },
                {
                    path: '/dashboard/start-with-ai',
                    element: <PromptChainsLanding />
                },
                {
                    path: '/dashboard/table/:id',
                    element: <TableDetail />
                },
                {
                    path: '/dashboard/tools/view/:id',
                    element: <ToolView />
                },
                {
                    path: '/dashboard/tools',
                    element: <Toollist />
                },
                {
                    path: '/dashboard/tables',
                    element: <TablesList />
                },
                {
                    path: '/dashboard/error-analytics',
                    element: <ErrorAnalytics />
                },
                {
                    path: '/dashboard/error-analytics/logs/:filename',
                    element: <LogViewer />
                },
                {
                    path: '/dashboard/upgrade',
                    element: <Upgrade />
                },
                {
                    path: '/dashboard/payment/success',
                    element: <PaymentSuccess />
                },
                {
                    path: '/dashboard/payment/failed',
                    element: <PaymentFailed />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/shared-workbook/:id/:rowId',
                    element: <SharedWorkbook />
                },
                {
                    path: '/dashboard/connections',
                    element: <Connections />
                },
                {
                    path: '/dashboard/usage',
                    element: <Credits />
                },
                {
                    path: '/dashboard/user/account',
                    element: <UserAccount />
                },
                {
                    path: '/dashboard/workspaces',
                    element: <TableWorkspaces />
                },
                {
                    path: '/dashboard/workspaces/:id/tables',
                    element: <WorkspaceTables />
                },
                {
                    path: '/dashboard/mcp/servers',
                    element: <MCPServerPublicList />
                },
                {
                    path: '/dashboard/mcp/servers/:id',
                    element: <MCPServerDetail />
                }
            ]
        }
        return MainRoutes
    }
}
export const PublicRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <Main />
        </GuestGuard>
    ),
    children: [
        {
            path: '/auth/logout',
            element: <Logout />
        },
        {
            path: '/',
            element: <Login />
        },
        {
            path: '/redirect',
            element: <OAuthCallback />
        },
        {
            path: '/templates/:id',
            element: (
                <>
                    <PublicLayout >
                        <TemplateDetailPublic />
                    </PublicLayout>
                </>
            ),
        },
        {
            path: '/mcp/servers',
            element: (
                <>
                    <PublicLayout >
                        <MCPServerPublicList />
                    </PublicLayout>
                </>
            ),
        },
        {
            path: '/mcp/servers/:id',
            element: (
                <>
                    <PublicLayout >
                        <MCPServerDetail />
                    </PublicLayout>
                </>
            ),
        }
    ]
}
export const NotFoundRoutes = {
    path: '*',
    element: <NotFound />
}
