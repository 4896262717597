
import { dispatch } from 'store'
import useNotifier from 'utils/useNotifier'
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from 'store/actions'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { SpinButton } from 'tailwindui/pages/components/Spin'
import Button from 'tailwindui/pages/components/Button'
import { Avatar } from '@mui/material'
export default function SocialAuth({ afterLogin }) {
    const [loading, setLoading] = useState(false)
    const [reqSent, setReqSent] = useState(false)
    const [bcMsg, setBcMsg] = useState(null)
    const [hasWorkEmailError, setWorkEmailError] = useState(null)
    const { login } = useAuth()
    const navigate = useNavigate()
    useNotifier()
    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args))
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args))
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const redirectUrl = process.env.REACT_APP_GOOGLE_REDIRECT_URL
    const scope = ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile']
    const authUrl = 'https://accounts.google.com/o/oauth2/auth'
    const bc = new BroadcastChannel('jippybot1')
    bc.onmessage = (event) => {
        if (event.data.type === 'oauth') {
            bc.close()
            setBcMsg(event.data.data)
        }
    }
    useEffect(() => {
        if (bcMsg) {
            save(bcMsg)
        }
    }, [bcMsg])
    const toast = (message, variant) => {
        enqueueSnackbar({
            message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant,
                persist: false,
                action: (key) => (
                    <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
                        <XMarkIcon aria-hidden='true' className='h-6 w-6' />
                    </Button>
                )
            }
        })
    }
    const handleGLogin = () => {
        setLoading(true)
        let url = `${authUrl}/oauthchooseaccount?response_type=code&client_id=`
        url += `${clientId}&redirect_uri=${redirectUrl}&access_type=offline&prompt=consent&scope=${scope.join(' ')}`
        console.log(url)
        const windowFeatures = 'width=500,height=700'

        const newWindow = window.open(url, 'auth', windowFeatures)

        // Optionally, you can focus the new window
        if (newWindow) {
            newWindow.focus()
        }
    }

    const save = async (data) => {
        if (reqSent) {
            return
        }
        if(!data.hd){
            setWorkEmailError(true)
            setLoading(false)
            return
            
        }
        setWorkEmailError(false)
        setReqSent(true)
        const payload = {
            client_id: clientId,
            code: data.code,
            redirect_url: redirectUrl,
            scope: scope.join(' ')
        }
        try {
            const err = await login(payload, afterLogin)
            if (err && err.error) {
                toast(err.error, 'error')
            } else if (err && err.status === false) {
                toast(err.msg, 'error')
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            const errorData = error?.response?.data?.message
            toast(errorData ?? `Failed to login`, 'error')
            setLoading(false)
        }
    }

    return (
        <>
            {hasWorkEmailError ? <div className='text-red-500 text-center mb-2 w-full'>
                Please use a business domain to login
            </div>: null}
            {
                loading ? <SpinButton title='Signing in...' fullWidth /> :
                    <Button variant='secondary' fullWidth
                        onClick={handleGLogin}
                    > 
                    <img src='/images/google.png' alt='Sign in with Google' className='h-5 w-5 mr-2' />
                    Sign in with Google</Button>
            }
        </>
    )
}
