import { useState } from 'react'
import * as Yup from 'yup'
import Page from '../../components/Page'
import SocialAuth from './SocialAuth'
import VerifyOtp from './VerifyOtp'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import TextField from 'tailwindui/pages/components/TextField'
import { Checkbox } from 'tailwindui/pages/components/Checkbox'
import { SpinButton } from 'tailwindui/pages/components/Spin'
import Button from 'tailwindui/pages/components/Button'
import { EyeOpenIcon, EyeClosedIcon } from '@radix-ui/react-icons'
import { PATH_AUTH } from '../../routes/paths'
import { useSnackbar } from 'notistack'

export default function Login() {
    const navigate = useNavigate()
    const search = window.location.search
    const params = new URLSearchParams(search)
    const redirectAfterLogin = params.get('redirectAfterLogin')
    const plan = params.get('plan')
    const { login, register } = useAuth()
    const { enqueueSnackbar } = useSnackbar()

    // Form mode state (login or register)
    const [formMode, setFormMode] = useState('login')
    const [isOtpSend, setOtpSend] = useState(false)
    
    // Login form state
    const [showPassword, setShowPassword] = useState(false)
    const [loginErrors, setLoginErrors] = useState({})
    const [isLoginSubmitting, setIsLoginSubmitting] = useState(false)
    
    // Register form state
    const [showRegisterPassword, setShowRegisterPassword] = useState(false)
    const [registerFormData, setRegisterFormData] = useState({
        FirstName: '',
        LastName: '',
        email: '',
        password: ''
    })
    const [registerErrors, setRegisterErrors] = useState({})
    const [isRegisterSubmitting, setIsRegisterSubmitting] = useState(false)

    // Validation schemas
    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    })
    
    const RegisterSchema = Yup.object().shape({
        FirstName: Yup.string().required('First name required'),
        LastName: Yup.string().required('Last name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    })

    // Form change handlers
    const handleLoginChange = (e) => {
        const { name, value, checked } = e.target
        setRegisterFormData({
            ...registerFormData,
            [name]: name === 'remember' ? checked : value
        })
    }
    
    const handleRegisterChange = (e) => {
        const { name, value } = e.target
        setRegisterFormData({
            ...registerFormData,
            [name]: value
        })
    }

    // Form validation
    const validateLoginForm = async () => {
        try {
            await LoginSchema.validate(registerFormData, { abortEarly: false })
            return {}
        } catch (error) {
            const validationErrors = {}
            error.inner.forEach((err) => {
                validationErrors[err.path] = err.message
            })
            return validationErrors
        }
    }
    
    const validateRegisterForm = async () => {
        try {
            await RegisterSchema.validate(registerFormData, { abortEarly: false })
            return {}
        } catch (error) {
            const validationErrors = {}
            error.inner.forEach((err) => {
                validationErrors[err.path] = err.message
            })
            return validationErrors
        }
    }

    const afterLogin = () => {
        if(plan) {
            navigate(`/dashboard/upgrade?plan=${plan}`)
        } else if (redirectAfterLogin) {
            window.location.href = redirectAfterLogin
        } else {
            navigate('/dashboard')
        }
    }

    // Form submission handlers
    const onLoginSubmit = async () => {
        const validationErrors = await validateLoginForm()
        
        if (Object.keys(validationErrors).length > 0) {
            setLoginErrors(validationErrors)
            return
        }
        
        setIsLoginSubmitting(true)
        try {
            const err = await login(registerFormData, afterLogin)
            console.log(err)
            if (err && err.error) {
                setLoginErrors({ afterSubmit: err.error })
            }
            if (err && err.status === false) {
                setLoginErrors({ afterSubmit: err.msg,hasUser:err.u })
            }
        } catch (error) {
            setLoginErrors({ afterSubmit: error.message })
        } finally {
            setIsLoginSubmitting(false)
        }
    }
    
    const otpSentCallback = (status, otp, msg) => {
        if (status) {
            setOtpSend(registerFormData.email)
        }
        enqueueSnackbar(msg, { variant: status ? 'success' : 'error' })
    }
    
    const onRegisterSubmit = async () => {
        const validationErrors = await validateRegisterForm()
        
        if (Object.keys(validationErrors).length > 0) {
            setRegisterErrors(validationErrors)
            return
        }
        
        setIsRegisterSubmitting(true)
        try {
            await register(registerFormData, otpSentCallback)
        } catch (error) {
            setRegisterErrors({ afterSubmit: error.message })
        } finally {
            setIsRegisterSubmitting(false)
        }
    }

    // If OTP is sent, show the OTP verification screen
    if (isOtpSend) {
        return <VerifyOtp email={isOtpSend} />
    }

    return (
        <Page title={formMode === 'login' ? 'Login' : 'Register'}>
            <div className='flex flex-col items-center justify-center min-h-screen py-12 px-4 sm:px-6 lg:px-8'>
                <div className='max-w-md w-full space-y-8'>
                    <img className='mx-auto h-12 w-auto' src='/logo/logo.svg' alt='Workflow' />
                </div>
                <div className='max-w-md w-full mt-10'>
                    {/* Tab navigation */}
                    <div className="flex border-b border-gray-200">
                        <button
                            className={`flex-1 py-2 px-4 text-center ${formMode === 'login' ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-500 hover:text-gray-700'}`}
                            onClick={() => setFormMode('login')}
                        >
                            Sign In
                        </button>
                        <button
                            className={`flex-1 py-2 px-4 text-center ${formMode === 'register' ? 'border-b-2 border-indigo-500 text-indigo-600' : 'text-gray-500 hover:text-gray-700'}`}
                            onClick={() => setFormMode('register')}
                        >
                            Register
                        </button>
                    </div>

                    
                    <div className='h-96'>
                    {formMode === 'login' ? (
                        /* Login Form */
                        <div className="">
                            <div className='h-12 my-4'>
                                {loginErrors.afterSubmit && (
                                    <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-1 rounded relative" role="alert">
                                        {loginErrors.hasUser ? <span className="block sm:inline">{loginErrors.afterSubmit}</span>
                                        :<span className="block sm:inline">Please register first</span>}
                                    </div>
                                )}
                            </div>

                            <div className="space-y-4">
                                <TextField 
                                    name="email" 
                                    label="Email address" 
                                    value={registerFormData.email}
                                    onChange={handleLoginChange}
                                />
                                {loginErrors.email && (
                                    <p className="text-red-500 text-xs mt-1">{loginErrors.email}</p>
                                )}

                                <div className="relative">
                                    <TextField
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={registerFormData.password}
                                        onChange={handleLoginChange}
                                    >
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? (
                                                <EyeOpenIcon className="h-4 w-4 text-gray-500" />
                                            ) : (
                                                <EyeClosedIcon className="h-4 w-4 text-gray-500" />
                                            )}
                                        </button>
                                    </TextField>
                                    {loginErrors.password && (
                                        <p className="text-red-500 text-xs mt-1">{loginErrors.password}</p>
                                    )}
                                </div>
                            </div>

                            <div className="flex items-center justify-between mt-4 mb-8">
                                <div className="flex items-center">
                                    <Checkbox 
                                        checked={registerFormData.remember}
                                        onChange={(e) => setRegisterFormData({...registerFormData, remember: e.target.checked})}
                                    />
                                    <label htmlFor="comments" className="ml-2 block text-sm text-gray-900">
                                        Remember me
                                    </label>
                                </div>
                                <div className="text-sm">
                                    <Link to={PATH_AUTH.resetPassword} className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>

                            {isLoginSubmitting ? (
                                <SpinButton title="Logging in" fullWidth />
                            ) : (
                                <Button variant="primary" fullWidth onClick={onLoginSubmit}>
                                    Login
                                </Button>
                            )}
                        </div>
                    ) : (
                        /* Register Form */
                        <div className="">
                            <div className='h-12 my-4'>
                                {registerErrors.afterSubmit && (
                                    <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-1 rounded relative" role="alert">
                                        <span className="block sm:inline">{registerErrors.afterSubmit}</span>
                                    </div>
                                )}
                            </div>

                            <div className="space-y-4 mb-12">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <TextField 
                                            name="FirstName" 
                                            label="First name" 
                                            value={registerFormData.FirstName}
                                            onChange={handleRegisterChange}
                                        />
                                        {registerErrors.FirstName && (
                                            <p className="text-red-500 text-xs mt-1">{registerErrors.FirstName}</p>
                                        )}
                                    </div>
                                    <div>
                                        <TextField 
                                            name="LastName" 
                                            label="Last name" 
                                            value={registerFormData.LastName}
                                            onChange={handleRegisterChange}
                                        />
                                        {registerErrors.LastName && (
                                            <p className="text-red-500 text-xs mt-1">{registerErrors.LastName}</p>
                                        )}
                                    </div>
                                </div>

                                <TextField 
                                    name="email" 
                                    label="Email address" 
                                    value={registerFormData.email}
                                    onChange={handleRegisterChange}
                                />
                                {registerErrors.email && (
                                    <p className="text-red-500 text-xs mt-1">{registerErrors.email}</p>
                                )}

                                <div className="relative">
                                    <TextField
                                        name="password"
                                        label="Password"
                                        type={showRegisterPassword ? 'text' : 'password'}
                                        value={registerFormData.password}
                                        onChange={handleRegisterChange}
                                    >
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                            onClick={() => setShowRegisterPassword(!showRegisterPassword)}
                                        >
                                            {showRegisterPassword ? (
                                                <EyeOpenIcon className="h-4 w-4 text-gray-500" />
                                            ) : (
                                                <EyeClosedIcon className="h-4 w-4 text-gray-500" />
                                            )}
                                        </button>
                                    </TextField>
                                    {registerErrors.password && (
                                        <p className="text-red-500 text-xs mt-1">{registerErrors.password}</p>
                                    )}
                                </div>
                            </div>

                            {isRegisterSubmitting ? (
                                <SpinButton title="Registering..." fullWidth />
                            ) : (
                                <Button variant="primary" fullWidth onClick={onRegisterSubmit}>
                                    Register
                                </Button>
                            )}
                        </div>
                    )}
                    </div>
                    <SocialAuth afterLogin={afterLogin} />
                </div>
            </div>
        </Page>
    )
}
