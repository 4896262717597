export const initialState = {
    servers: [],
    server: null,
    tools: [],
    loading: false,
    error: null
}

const mcpReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MCP_SERVERS':
            return {
                ...state,
                servers: Array.isArray(action.payload) ? action.payload : []
            }
        case 'SET_MCP_SERVER':
            return {
                ...state,
                server: action.payload
            }
        case 'ADD_MCP_SERVER':
            return {
                ...state,
                servers: Array.isArray(state.servers) 
                    ? [...state.servers, action.payload] 
                    : [action.payload]
            }
        case 'UPDATE_MCP_SERVER':
            return {
                ...state,
                servers: Array.isArray(state.servers) 
                    ? state.servers.map((server) => 
                        server.id === action.payload.id ? action.payload : server
                      )
                    : []
            }
        case 'DELETE_MCP_SERVER':
            return {
                ...state,
                servers: Array.isArray(state.servers) 
                    ? state.servers.filter((server) => server.id !== action.payload)
                    : []
            }
        case 'SET_MCP_LOADING':
            return {
                ...state,
                loading: action.payload
            }
        case 'SET_MCP_ERROR':
            return {
                ...state,
                error: action.payload
            }
        case 'SET_MCP_SERVER_TOOLS':
            return {
                ...state,
                tools: Array.isArray(action.payload) ? action.payload : []
            }
        default:
            return state
    }
}

export default mcpReducer
