export const initialState = {
    currentTab: 'ContentBrief',
    row: null,
    cells: [],
    loadingStep: null,
    socketData: {
        socketId: '',
        roomId: '',
        data: []
    },
    stepCompleted: [],
    refresh: null,
    aiBlogPage: null,
    tokens: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TOKENS':
            return {
                ...state,
                tokens: { ...state.tokens, [action.payload.key]: action.payload.value }
            }
        case 'INIT_NEW_AI_PAGE':
            return initialState
        case 'SET_AI_PAGES':
            return {
                ...state,
                aiBlogPage: action.payload
            }
        case 'STEP_COMPLETED':
            return {
                ...state,
                stepCompleted: [...state.stepCompleted, action.payload]
            }
        case 'GET_PAGE_TIME':
            return {
                ...state,
                refresh: action.payload
            }
        case 'SET_CURRENT_TAB':
            return {
                ...state,
                currentTab: action.payload
            }
        case 'SET_ROW':
            return {
                ...state,
                row: action.payload
            }
        case 'SET_CELLS':
            return {
                ...state,
                cells: action.payload
            }
        case 'SET_LOADING':
            return {
                ...state,
                loadingStep: action.payload
            }
        case 'SET_BUILDER_SOCKET_DATA':
            if (action.payload.data) {
                const consversations = [...state.socketData.data, action.payload.data]
                console.log(consversations)
                return {
                    ...state,
                    socketData: {
                        ...state.socketData,
                        data: consversations
                    }
                }
            } else {
                return {
                    ...state,
                    socketData: {
                        ...state.socketData,
                        ...action.payload
                    }
                }
            }
        default:
            return state
    }
}

export default reducer
