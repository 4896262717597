// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password')
}

export const PATH_PAGE = {
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    pricing: '/pricing',
    payment: '/payment',
    about: '/about-us',
    contact: '/contact-us',
    faqs: '/faqs',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components'
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    logs: {
        api: path(ROOTS_DASHBOARD, '/api-logs'),
        client: path(ROOTS_DASHBOARD, '/client-logs')
    },
    general: {
        app: path(ROOTS_DASHBOARD, '/app'),
        ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
        analytics: path(ROOTS_DASHBOARD, '/analytics'),
        banking: path(ROOTS_DASHBOARD, '/banking'),
        booking: path(ROOTS_DASHBOARD, '/booking')
    },
    mail: {
        root: path(ROOTS_DASHBOARD, '/mail'),
        all: path(ROOTS_DASHBOARD, '/mail/all')
    },
    chat: {
        root: path(ROOTS_DASHBOARD, '/chat'),
        new: path(ROOTS_DASHBOARD, '/chat/new'),
        view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`)
    },
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    kanban: path(ROOTS_DASHBOARD, '/kanban'),
    permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        new: path(ROOTS_DASHBOARD, '/user/new'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
        cards: path(ROOTS_DASHBOARD, '/user/cards'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        account: path(ROOTS_DASHBOARD, '/user/account'),

        edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`)
    },
    users: {
        root: path(ROOTS_DASHBOARD, '/users'),
        list: path(ROOTS_DASHBOARD, '/users'),
        new: path(ROOTS_DASHBOARD, '/users/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/users/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/users/${id}/edit`)
    },
    student: {
        root: path(ROOTS_DASHBOARD, '/student'),
        list: (cohortId) => path(ROOTS_DASHBOARD, `/cohort/${cohortId}`),
        new: (cohortId) => path(ROOTS_DASHBOARD, `/cohort/${cohortId}/student/new`),
        view: (cohortId, id) => path(ROOTS_DASHBOARD, `/cohort/${cohortId}/student/${id}`),
        edit: (cohortId, id) => path(ROOTS_DASHBOARD, `/cohort/${cohortId}/student/${id}/edit`)
    },
    cohort: {
        root: path(ROOTS_DASHBOARD, '/cohort'),
        list: path(ROOTS_DASHBOARD, '/cohort'),
        new: path(ROOTS_DASHBOARD, '/cohort/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/cohort/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/cohort/${id}/edit`),
        cohortregisterCSV: (id) => path(ROOTS_DASHBOARD, `/cohort/import-csv/${id}`)
    },
    assessment: {
        root: path(ROOTS_DASHBOARD, '/assessment'),
        list: path(ROOTS_DASHBOARD, '/assessment'),
        new: path(ROOTS_DASHBOARD, '/assessment/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/assessment/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/assessment/${id}/edit`),
        assessmentregisterCSV: (id) => path(ROOTS_DASHBOARD, `/assessment/import-csv/${id}`)
    },
    report: {
        assessmentroot: path(ROOTS_DASHBOARD, '/assessment-report'),
        root: path(ROOTS_DASHBOARD, '/report'),
        list: path(ROOTS_DASHBOARD, `/report`)
    },
    coursefigma: {
        root: path(ROOTS_DASHBOARD, '/coursefigma')
    },
    companies: {
        root: path(ROOTS_DASHBOARD, '/companies'),
        list: path(ROOTS_DASHBOARD, '/companies'),
        new: path(ROOTS_DASHBOARD, '/companies/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/companies/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/companies/${id}/edit`)
    },
    waitlist: {
        root: path(ROOTS_DASHBOARD, '/waitlist')
    },
    feedback: {
        root: path(ROOTS_DASHBOARD, '/feedback')
    },
    emailTemplates: {
        root: path(ROOTS_DASHBOARD, '/email-templates'),
        list: path(ROOTS_DASHBOARD, '/email-templates'),
        new: path(ROOTS_DASHBOARD, '/email-templates/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/email-templates/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/email-templates/${id}/edit`)
    },
    course: {
        root: path(ROOTS_DASHBOARD, '/course'),
        lesson: (CourseId) => path(ROOTS_DASHBOARD, `/course/${CourseId}`),
        newLesson: (CourseId) => path(ROOTS_DASHBOARD, `/course/${CourseId}/new`),
        editLesson: (CourseId, id) => path(ROOTS_DASHBOARD, `/course/${CourseId}/${id}/edit`),
        viewLesson: (CourseId, id) => path(ROOTS_DASHBOARD, `/course/${CourseId}/view/${id}`),
        lectures: (CourseId, lessonId) => path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}`),
        newLecture: (CourseId, lessonId) => path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}/lecture/new`),
        editLecture: (CourseId, lessonId, id) => path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}/lecture/${id}/edit`),
        viewLecture: (CourseId, lessonId, id) => path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}/lecture/view/${id}`),
        exercises: (CourseId, lessonId, lectureId, subtype) =>
            path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}/lecture/${lectureId}?language=${subtype}`),
        newExercise: (CourseId, lessonId, lectureId) =>
            path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}/lecture/${lectureId}/exercise/new`),
        editExercise: (CourseId, lessonId, lectureId, id) =>
            path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}/lecture/${lectureId}/exercise/${id}/edit`),
        viewExercise: (CourseId, lessonId, lectureId, id) =>
            path(ROOTS_DASHBOARD, `/course/${CourseId}/lesson/${lessonId}/lecture/${lectureId}/exercise/view/${id}`),
        new: path(ROOTS_DASHBOARD, '/course/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/course/view/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/course/${id}/edit`)
    },
    testset: {
        root: path(ROOTS_DASHBOARD, '/testset'),
        new: path(ROOTS_DASHBOARD, '/testset/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/testset/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/testset/${id}/edit`)
    },
    eCommerce: {
        root: path(ROOTS_DASHBOARD, '/e-commerce'),
        shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
        list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
        checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
        new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
        view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
        edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
        demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt')
    },

    lectures: {},
    lessons: {
        new: path(ROOTS_DASHBOARD, '/lessons/lesson/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/lessons/lesson/view/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/lessons/lesson/${id}/edit`)
    },
    companys: {
        company: path(ROOTS_DASHBOARD, '/companys/company'),
        new: path(ROOTS_DASHBOARD, '/companys/company/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/companys/company/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/companys/company/${id}/edit`)
    },
    tallys: {
        tally: path(ROOTS_DASHBOARD, '/tallys/tally'),
        new: path(ROOTS_DASHBOARD, '/tallys/tally/new'),
        view: (name) => path(ROOTS_DASHBOARD, `/tallys/tally/${name}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/tallys/tally/${id}/edit`)
    },
    teams: {
        team: path(ROOTS_DASHBOARD, '/teams/team'),
        new: path(ROOTS_DASHBOARD, '/teams/team/new'),
        view: (name) => path(ROOTS_DASHBOARD, `/teams/team/${name}`),
        edit: (name) => path(ROOTS_DASHBOARD, `/teams/team/${name}/edit`)
    },
    invoice: {
        root: path(ROOTS_DASHBOARD, '/invoice'),
        list: path(ROOTS_DASHBOARD, '/invoice/list'),
        new: path(ROOTS_DASHBOARD, '/invoice/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
        demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5')
    },
    blog: {
        root: path(ROOTS_DASHBOARD, '/blogs'),
        new: path(ROOTS_DASHBOARD, '/blogs/new'),
        view: (title) => path(ROOTS_DASHBOARD, `/blogs/${title}`)
    },
    workshop: {
        root: path(ROOTS_DASHBOARD, '/workshops'),
        new: path(ROOTS_DASHBOARD, '/workshops/new'),
        view: (title) => path(ROOTS_DASHBOARD, `/workshops/${title}`)
    },
    batch: {
        root: path(ROOTS_DASHBOARD, '/batch'),
        dashboard: path(ROOTS_DASHBOARD, '/batch/dashboard')
    },
    mcp: {
        root: path(ROOTS_DASHBOARD, '/mcp'),
        servers: path(ROOTS_DASHBOARD, '/mcp/servers')
    }
}

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction'
