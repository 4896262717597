/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { motion, useAnimationFrame, useMotionTemplate, useMotionValue, useTransform } from 'framer-motion'
import { useRef } from 'react'
import { cn } from './utils'
const Button = ({ variant, disabled, children, fullWidth, py='py-2', ...props }) => {
    const variantClasses = {
        primary:
            'bg-mtmain text-white hover:bg-mtmainHover hover:text-white focus:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mtmain',
        danger:
            'bg-red-600 text-white hover:bg-red-700 hover:text-white focus:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white',
        secondary:
            'bg-mtbtnmain  hover:bg-mtbtnmainHover focus:outline-none  outline outline-0 outline-offset-0 text-gray-600',
        next: 'bg-mtgreen text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mtmain hover:text-mtgreen hover:bg-white hover:border-mtgreen',
        back: 'bg-white text-gray-900 hover:bg-mtlight outline outline-1 outline-offset-0 mr-5 hover:text-white hover:bg-black',
        simple: 'bg-mtbtnmain  hover:bg-mtbtnmainHover focus:outline-none  outline outline-0 outline-offset-0 text-gray-600 ',

    }
    const onBtnClick = (e) => {
        e.preventDefault()
        if (props.onClick) {
            props.onClick()
        }
    }
    return (
        <button
            className={`inline-flex justify-center ${py} px-4 border border-transparent shadow-sm text-sm font-medium rounded-md ${variantClasses[variant]
                } ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${fullWidth ? 'w-full' : ''}`}
            disabled={disabled}
            {...props}
            onClick={onBtnClick}
            data-attr='autocapture-button'
        >
            {children}
        </button>
    )
}
Button.defaultProps = {
    variant: 'primary'
}
Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired
}
export default Button

export function MovingBorderButton({
    borderRadius = '1.75rem',
    children,
    as: Component = 'button',
    containerClassName,
    borderClassName,
    duration,
    className,
    ...otherProps
}) {
    return (
        <Component
            className={cn('bg-transparent relative h-16 w-40 p-[1px] overflow-hidden ', containerClassName)}
            style={{
                borderRadius: borderRadius
            }}
            {...otherProps}
        >
            <div className='absolute inset-0' style={{ borderRadius: `calc(${borderRadius} * 0.96)` }}>
                <MovingBorder duration={duration} rx='30%' ry='30%'>
                    <div
                        className={cn('h-20 w-20 opacity-[0.8] bg-[radial-gradient(var(--sky-500)_40%,transparent_60%)]', borderClassName)}
                    />
                </MovingBorder>
            </div>

            <div
                className={cn(
                    'relative bg-slate-900/[0.8] border border-slate-800 backdrop-blur-xl text-white flex items-center justify-center w-full h-full text-sm antialiased',
                    className
                )}
                style={{
                    borderRadius: `calc(${borderRadius} * 0.96)`
                }}
            >
                {children}
            </div>
        </Component>
    )
}

export const MovingBorder = ({ children, duration = 2000, rx, ry, ...otherProps }) => {
    const pathRef = useRef()
    const progress = useMotionValue(0)

    useAnimationFrame((time) => {
        const length = pathRef.current?.getTotalLength()
        if (length) {
            const pxPerMillisecond = length / duration
            progress.set((time * pxPerMillisecond) % length)
        }
    })

    const x = useTransform(progress, (val) => pathRef.current?.getPointAtLength(val).x)
    const y = useTransform(progress, (val) => pathRef.current?.getPointAtLength(val).y)

    const transform = useMotionTemplate`translateX(${x}px) translateY(${y}px) translateX(-50%) translateY(-50%)`

    return (
        <>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                preserveAspectRatio='none'
                className='absolute h-full w-full'
                width='100%'
                height='100%'
                {...otherProps}
            >
                <rect fill='none' width='100%' height='100%' rx={rx} ry={ry} ref={pathRef} />
            </svg>
            <motion.div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'inline-block',
                    transform
                }}
            >
                {children}
            </motion.div>
        </>
    )
}
