import App from './App'
import './assets/scss/global.css'
import './assets/scss/proseMirror.css'
import { store } from 'store'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import ConfirmContextProvider from 'store/context/ConfirmContextProvider'
import { AuthProvider } from 'store/context/JWTContext'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
import 'intro.js/introjs.css';
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only'
})
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <>
        <PostHogProvider client={posthog}>
            <AuthProvider>
                <HelmetProvider>
                    <Provider store={store}>
                        <BrowserRouter>
                            <SnackbarProvider>
                                <ConfirmContextProvider>
                                    <App />
                                </ConfirmContextProvider>
                            </SnackbarProvider>
                        </BrowserRouter>
                    </Provider>
                </HelmetProvider>
            </AuthProvider>
        </PostHogProvider>
    </>
)
