import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Create a global variable to track if an update is available
let updateAvailable = false;

// Function to check if an update is needed
export const isUpdateAvailable = () => updateAvailable;

// Function to force a refresh
export const forceRefresh = () => {
  window.location.reload(true);
};

const useVersionCheck = (interval = 60000) => { // Check every 60 seconds
  const [currentVersion, setCurrentVersion] = useState(null);
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState(location.pathname);

  // Check for version updates
  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch("/version.json?ts=" + new Date().getTime()); // Bypass cache
        const data = await response.json();

        if (!currentVersion) {
          setCurrentVersion(data.version);
        } else if (currentVersion !== data.version) {
          console.log("New version detected! Will refresh on next navigation.");
          updateAvailable = true;
        }
      } catch (error) {
        console.error("Error checking version:", error);
      }
    };

    checkVersion();
    const intervalId = setInterval(checkVersion, interval);

    return () => clearInterval(intervalId);
  }, [currentVersion, interval]);

  // Check for route changes and refresh if needed
  useEffect(() => {
    // If the path has changed and an update is available
    if (previousPath !== location.pathname && updateAvailable) {
      console.log("Route changed and update available. Refreshing...");
      window.location.reload(true);
    }

    // Update the previous path
    setPreviousPath(location.pathname);
  }, [location.pathname, previousPath]);

  return {
    currentVersion,
    updateAvailable: isUpdateAvailable
  };
};

export default useVersionCheck;
