import { Suspense } from 'react'
const Loadable = (Component) =>
    function WithLoader(props) {
        return (
            <Suspense>
                <Component {...props} />
            </Suspense>
        )
    }

export default Loadable
