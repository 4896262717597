/* eslint-disable import/no-anonymous-default-export */
import client from './client'
import provider from 'api/provider'
import toolApi from 'api/tools'
import { dispatch } from 'store'
import agents from 'api/agents'
import category from 'api/category'
const saveLocalConnection = (payload) => client.post(`/app-connections`, payload)
const getLocalConnections = (isSytem) => client.get(isSytem ? `/app-connections?system=true` : `/app-connections`)
export default {
    saveLocalConnection,
    getLocalConnections,
    deleteConnection: (id, isSystem) => client.delete(isSystem ? `/app-connections/${id}?system=true` : `/app-connections/${id}`),
    updateConnection: (id, payload, isSystem) =>
        client.put(isSystem ? `/app-connections/${id}?system=true` : `/app-connections/${id}`, payload),
    updateServerEnv: (payload) => client.post(`/app-connections/mcp-env`, payload),
    addConnection: (payload, isSystem) => client.post(isSystem ? `/app-connections?system=true` : `/app-connections`, payload),
    doLogin: (payload) => client.post(`/app-connections/doLogin`, payload),
    submitOtp: (payload) => client.post(`/app-connections/submitOtp`, payload)
}
export const getCategoriesToolConnections = async () => {
    try {
        dispatch({ type: 'SET_CONNECTIONS_LOADING', payload: true })
        const [tools, connections, categories, providers] = await Promise.all([
            toolApi.getTools(),
            agents.getLocalConnections(),
            category.getCategories(),
            provider.getProviders()
        ])
        if (connections.data) dispatch({ type: 'SET_CONNECTIONS', payload: connections.data })
        if (categories.data) dispatch({ type: 'SET_CATEGORIES', payload: categories.data })
        if (providers.data) dispatch({ type: 'SET_PROVIDERS', payload: providers.data })
        if (tools.data) dispatch({ type: 'SET_TOOLS', payload: tools.data })
        dispatch({ type: 'SET_CONNECTIONS_LOADING', payload: false })
    } catch (error) {
        console.log(error)
    }
}
